
import ThridPage from './threePage.vue';
import FirstPage from './onePage.vue';
import SecondPage from './twoPage.vue';
import pageFour from './fourPage.vue';
import pageFive from './fivePage.vue';
import pageSix from './finalPage.vue';
import ai from './ai.vue';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import SwiperCore, { Navigation, Mousewheel, A11y, Pagination } from 'swiper';

SwiperCore.use([Navigation, Mousewheel, A11y, Pagination]);
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue';
import { Swiper as SwiperEvents } from 'swiper/types';
import axios from 'axios';
export default defineComponent({
  components: {
    ThridPage,
    FirstPage,
    SecondPage,
    pageFour,
    pageSix,
    Swiper,
    pageFive,
    SwiperSlide,
    ai
  },
  setup() {
    let showToast = ref(false);
    // 将swiper实例保存
    let swiperInstance: any;
    let data = reactive({
      swiperIndex: 0
    });
    let mainDownloadLink = ref('');
    let downloadShow = ref(false);
    //判断是ios或者and 1为安卓手机 2为苹果手机
    let iosOrAnd = ref(1);
    //对应变色的导航
    let activeA = ref(1);
    const onSwiper = (swiper?: any) => {
      swiper.slideTo(0);
      swiperInstance = swiper;
    };
    const onActiveIndexChange = (swiper: SwiperEvents) => {
      data.swiperIndex = swiper.activeIndex;
    };

    //点击导航栏变色
    const activeNav = (index: number) => {
      activeA.value = index;
    };
    function isAndroid() {
      let _ua = window.navigator.userAgent.toLocaleLowerCase();
      if (_ua.indexOf('android') > -1 || _ua.indexOf('adr') > -1) {
        return true;
      } else {
        return false;
      }
    }

    let isWeixinOpen = ref(false);
    // 判断是否在微信浏览器打开
    const isWeixin = () => {
      let ua = navigator.userAgent.toLowerCase();
      let isWeixing = ua.indexOf('micromessenger') != -1;
      if (isWeixing && iosOrAnd.value === 2) {
        isWeixinOpen.value = true;
      } else {
        isWeixinOpen.value = false;
      }
    };
    // 安卓点击下载
    const downloadAnd = (event) => {
      let ua = navigator.userAgent.toLowerCase();
      let platform = navigator.platform;
      if (
        ua.indexOf('micromessenger') != -1 &&
        (platform.indexOf('Win') == 0 || platform.indexOf('Mac') == 0)
      ) {
        showToast.value = true;
        event.preventDefault();
        setTimeout(() => {
          showToast.value = false;
        }, 3000);
        return;
      }
    };

    const downloadLead = (event) => {
      let ua = navigator.userAgent.toLowerCase();
      let isWeixing = ua.indexOf('micromessenger') != -1;
      if (isWeixing) {
        isWeixinOpen.value = true;
      } else {
        downloadShow.value = true;
      }
      event.preventDefault();
    };

    function isIos() {
      let _ua = window.navigator.userAgent;
      if (_ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        return true;
      } else {
        return false;
      }
    }

    const isIosOrAnd = () => {
      if (isAndroid()) {
        //安卓手机
        iosOrAnd.value = 1;
      } else if (isIos()) {
        //苹果手机
        iosOrAnd.value = 2;
      }
    };

    function getMdnLink() {
      axios
        .get('/yimo-api/sys/switch/ios/mdm/link')
        .then((response) => {
          const res = response.data;
          if (res.code === 200) {
            mainDownloadLink.value = res.data;
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }

    onMounted(() => {
      onActiveIndexChange(swiperInstance);
      isIosOrAnd();
      isWeixin();
      // getMdnLink();
    });
    return {
      onSwiper,
      ...toRefs(data),
      activeA,
      isIosOrAnd,
      onActiveIndexChange,
      activeNav,
      iosOrAnd,
      isWeixinOpen,
      downloadLead,
      downloadAnd,
      mainDownloadLink,
      downloadShow,
      showToast
    };
  }
});
